import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from './pages/login/loginPage';
import HomePage from "./pages/home/HomePage";

import './App.css'

const App: React.FC = () => {
    return (
        <div className="app">
            <Router>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/" element={<HomePage />} />
                </Routes>
            </Router>
        </div>
    );
};

export default App;
