import React from 'react';
import { Button, Card } from 'antd';
import { useNavigate } from 'react-router-dom';

import logo from "./VIXY_Logo_RGB.svg"
import icon from "./azure-active-directory.svg"
import './loginPage.css'

const LoginPage: React.FC = () => {
    const navigate = useNavigate();

    const url = process.env.REACT_APP_BACKEND_URI + '/login/saml';

    const handleLogin = () => {
        // Example login logic here
        console.log('Login action');
        // Redirect after login
        navigate('/');
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card
                className="login-cover"
                style={{ width: 350 }}
                cover={<img alt="example" src={logo} height="80px" />} // Adjust the path to your logo
            >
                <p>Please log in to continue.</p>
                <a href={url}>
                    <Button type="default" size="large">
                        <img src={icon} alt="icon" width="20px" style={{ marginRight: 8 }} />
                        Login With Microsoft
                    </Button>
                </a>
            </Card>
        </div>
    );
};

export default LoginPage;
