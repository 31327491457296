import React, { useState, useEffect } from 'react';
import {Card, List, Typography, Badge, Table, Space, Button} from 'antd';
import axiosInstance from "../../services/axsiox";
import {render} from "@testing-library/react";

interface Server {
    name: string;
    id: string;
    status: string;
    owner: string;
    online_since: string;
    role: ServerRole
}

enum ServerRole{
    usable = 'usable',
    required = 'required',
    always = 'always'
}
interface ServerList {
    servers: Server[],
    running: number
}

const columns = [
    {
        title: 'Id',
        dataIndex: 'id', // Field in the data source
        key: 'id', // Unique key for each column
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
    },
    {
        title: 'Online Since',
        dataIndex: 'online_since',
        key: 'online_since',
    },
    {
        title: 'Owner',
        dataIndex: 'owner',
        key: 'owner',
    },
    {
        title: 'Actions',
        key: 'actions',
        render: (text: any, record: Server) => (
            <Space size="middle">
                {record.role == ServerRole.usable && record.status == 'stopped' ? <Button onClick={() => start(record)}>Start</Button> : ''}
                {record.role == ServerRole.usable && record.status == 'running' ? <Button onClick={() => stop(record)}>Stop</Button> : ''}
            </Space>
        )
    }
];

function start(server: Server){
    axiosInstance
        .post(`server/${server.id}/start`)
        .then((response) => {
            return;
        })
        .catch((error) => {
            console.log(error);
        });
}
function stop(server: Server){
    axiosInstance
        .post(`server/${server.id}/stop`)
        .then((response) => {
            return;
        })
        .catch((error) => {
            console.log(error);
        });
}


// Data source
const data = [
    {
        key: '1',
        name: 'John Doe',
        age: 32,
        address: '10 Downing Street',
    },
    // Add more items...
];

const HomePage: React.FC = () => {
    const [servers, setServers] = useState<Server[]>([]);
    const [other, setOther] = useState<Server[]>([]);

    const fetchServers = async () => {
        axiosInstance
            .get<ServerList>("/server")
            .then((response) => {
                const usable: Server[] = response.data.servers.filter(server => server.role == ServerRole.usable);
                setServers(usable);
                let other: Server[] =response.data.servers.filter(server => server.role != ServerRole.usable);
                setOther(other);

            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        fetchServers(); // Fetch data on component mount
        const interval = setInterval(fetchServers, 5000); // Set up the interval to fetch data every 5 seconds

        return () => clearInterval(interval); // Clean up the interval on component unmount
    }, []); // Empty dependency array ensures this effect runs only once on mount




    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <Card title="Server List" style={{ width: 1000 }}>
                <Table columns={columns} dataSource={servers} />
                <h2>Required</h2>
                <Table columns={columns} dataSource={other} />
            </Card>
        </div>
    );
};

export default HomePage;
